import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Showing Gratitude Towards Teachers`}</h1>
    <p>{`After interviewing over a hundred freshman, sophomores, and juniors via Instagram and text messaging, it became clear to me that students are not able to create good relationships with their teachers. This was especially true for students in IVA or freshman in the hybrid model.`}</p>
    <p>{`In a typical year, teachers and students would get to know each other from conversations, class discussions, projects, and more. Since much of that is halted, it's hard to get to know your teacher and for your teacher to get to know you! It usually is not ASB fixing this issue, but ASB can run events to help bridge the gap. For example, what if there was a `}<strong parentName="p">{`Secret Life of Teachers/Counselors`}</strong>{` event either virtually or on campus? I learned about this idea because it's something that YAT has done in the past and I thought it sounds like a fantastic idea!`}</p>
    <p>{`Whether it be a game show style where a student will guess a teachers favorite color and they will write it on the board without showing, or it just be interviews with teachers to know: do they have pets, what their favorite foods are, etc!`}</p>
    <p>{`Our teachers has spent so much time, effort, and just pulled their hair out over how difficult this year has been from an instructional perspective. They spend so much time working to create lesson plans, grading papers, and teaching classes, they deserve more appreciation from the student body! Student Forum runs a Stafftastics event, which is another outreach event to bring spotlight to teachers as well! What if students secretly decorated teacher's doors without them knowing?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      